(function() {
//=require ../js/app.config.js

/**
 * Platforms
 */
(function() {

  const $body = $('body');

  if (rgjs.platforms.isMobile()) {
    $body.addClass('is-mobile');
  }

}());

/**
 * On DOM Content Loaded
 */
(function() {

  const $body = $('body');

  rgjs.events.on('DOMContentLoaded', window, (event) => {

    console.log('window.DOMContentLoaded');

    // Body has loaded
    $body.addClass('has-loaded');

    // Load fonts..
    rgjs.ajax.dom.loadList(
      [
        {type: 'style', url: 'https://fonts.googleapis.com/css?family=Roboto:300,400,600&display=swap', opts: {attrs: {id: 'font-roboto'}}},
        {type: 'style', url: 'https://fonts.googleapis.com/css?family=Roboto+Slab:400&display=swap', opts: {attrs: {id: 'font-roboto-slab'}}},
      ]
    );

    // Load page.home
    const page = rgjs.uri.getParam('page', 'home');
    window.loadPage(`ajax/page.${page}.html`);

  });

}());

/**
 * Header/-small switcher
 */
(function() {

  const $window = $(window);
  const $header = $('.header');
  const $headerSmall = $('.header-small');

  rgjs.events.on('scroll', window, (event) => {

    if ($window.scrollTop()<128) {
      $header.removeClass('hidden');
      $headerSmall.addClass('hidden');
    }
    else {
      $header.addClass('hidden');
      $headerSmall.removeClass('hidden');
    }

  });
  rgjs.events.trigger('scroll', window);

}());

/**
 * Global: loadPage()
 */
(function() {

  const $main = $('.main');
  const $footer = $('.footer');

  const loadPage = window.loadPage = (url) => {
    $main.html('<section><p>Loading...</p></section>');
    $footer.addClass('hide');
    $main.load(url, () => {
      _handleHash();
      $footer.removeClass('hide');
    });
  };

  const _handleHash = () => {
    const hash = window.location.hash;
    if (!hash) return;
    const $hash = $(hash);
    if ($hash.length === 0) return;
    if ('scrollIntoView' in $hash.get(0)) {
      $hash.get(0).scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
  };

})();

}());
